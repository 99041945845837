import * as React from 'react';
import { IBulletInformation } from '../../../../typings/BulletInformation';


import { ArticlePicker } from './components/ArticlePicker';

import './styles.scss';

// The duration of each carousel slide in ms
const CAROUSEL_DURATION = 5000;

interface IProps {
  data: IBulletInformation[];
}

interface IState {
  selectedArticle: IBulletInformation;
  shouldRotate: boolean;
}

export class InformationCarousel extends React.Component<
  IProps,
  IState
> {
  public state = {
    selectedArticle: this.props.data[0],
    shouldRotate: true,
  };

  private carouselInterval: NodeJS.Timeout | null = null;

  constructor(props: IProps) {
    super(props);

    this.startCarousel();
  }

  public render() {
    return (
      <section
        className="information-carousel d-flex"
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
      >
        <div className="information-carousel__picker d-flex pl-5 pr-5">
          {this.props.data.map(bulletInformation => (
            <ArticlePicker
              active={
                this.state.selectedArticle.id === bulletInformation.id
              }
              label={bulletInformation.title}
              onClick={() => {
                this.onArticleClick(bulletInformation);
              }}
              key={bulletInformation.id}
            />
          ))}
        </div>
        <article
          className="information-carousel__article"
          dangerouslySetInnerHTML={{
            __html: this.state.selectedArticle.content,
          }}
        />
      </section>
    );
  }

  private startCarousel = () => {
    this.carouselInterval = setInterval(() => {
      const selectedIndex = this.props.data.findIndex(
        bulletInformation =>
          bulletInformation.id === this.state.selectedArticle.id,
      );

      // Select the next bulletInformation or the first one, when last one is selected
      const index =
        selectedIndex === this.props.data.length - 1
          ? 0
          : selectedIndex + 1;

      this.setState({
        selectedArticle: this.props.data[index],
      });
    }, CAROUSEL_DURATION);
  };

  private stopCarousel = () => {
    if (this.carouselInterval === null) {
      return;
    }

    clearInterval(this.carouselInterval);
    this.carouselInterval = null;
  };

  private onMouseEnter = () => {
    this.stopCarousel();
  };

  private onMouseLeave = () => {
    this.startCarousel();
  };

  private onArticleClick = (
    bulletInformation: IBulletInformation,
  ) => {
    this.setState({
      selectedArticle: bulletInformation,
    });
  };
}
