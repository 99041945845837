import * as React from 'react';
import './styles.scss';

interface IProps {
  children: React.ReactChild;
  href: string;
  style?: React.CSSProperties;
}

export const Tile: React.FC<IProps> = ({
  children,
  href,
  style,
}) => (
  <a
    href={href}
    style={style}
    className="tile d-flex justify-content-center align-items-center text-align-center w-100"
  >
    {children}
  </a>
);

Tile.defaultProps = {
  style: {},
};
