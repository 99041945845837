import * as React from 'react';

import './styles.scss';

export const Hero = () => (
  <div className="hero">
    <div className="hero__left">{'REIN\nRAUS'}</div>
    <div className="hero__right">{'GEHEN\nFAHREN'}</div>
  </div>
);
