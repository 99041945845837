import * as React from 'react';

import './styles.scss';

interface IProps {
  children: React.ReactChild | React.ReactChild[];
  title: string;
  image: string;
  id: string;
}

export const Section = ({ id, title, children, image }: IProps) => (
  <section className="section" id={id}>
    <h1 className="section__title">{title}</h1>
    <div className="section__content">{children}</div>
    <div
      className="section__image"
      style={{
        backgroundImage: `url("${image}")`,
      }}
    />
  </section>
);
