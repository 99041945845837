import * as React from "react";

import { IBulletInformation } from "../../typings/BulletInformation";
import { BlackFriday } from "../../components/BlackFriday";
import { Tile } from "../../components/Tile";
import { FullScreenLoader } from "../../scenes/FullScreenLoader";
import { Header } from "../../scenes/Header";

import { Hero } from "./components/Hero";
import { InformationCarousel } from "./scenes/InformationCarousel";

import "./styles.scss";

interface IState {
  bulletInformation: IBulletInformation[] | null;
  loading: boolean;
}

const BULLET_INFORMATION = [
  {
    "@id": "/api/bullet_informations/41c4ec12-c13a-4675-8833-928e54d51991",
    "@type": "BulletInformation",
    content:
      "\u003Cp\u003EDu bist bereits berufstätig? Du möchtest so schnell wie möglich die Theorie hinter dich bringen? Du fühlst Dich unsicher und möchtest nichts verpassen? Wir unterrichten 6x die Woche (nach Bedarf) und bieten das ganze Jahr über Intensivkurse an (Führerschein in 2 Wochen möglich), um Dich schnellstmöglich auf die Straße zu bringen. Das Büro ist täglich für Dich geöffnet.\u003C/p\u003E\u003Cp\u003E\u003Cbr\u003E\u003C/p\u003E\u003Cp\u003EJede Einheit behandelt in sich geschlossene Themenbereiche, sodass Du zu jeder Zeit in den Unterricht einsteigen kannst, ohne befürchten zu müssen, dass Du überfordert wirst.\u003C/p\u003E",
    id: "41c4ec12-c13a-4675-8833-928e54d51991",
    title: "Für jeden",
  },
  {
    "@id": "/api/bullet_informations/7b6ffefe-6f49-43c7-8482-d9d393c7c666",
    "@type": "BulletInformation",
    content:
      "\u003Cp\u003EVon Fahrzeugen, über die Theorie, bis zur technischen Integration - wir sind immer auf dem aktuellsten Stand!\u003C/p\u003E\u003Cp\u003E\u003Cbr\u003E\u003C/p\u003E\u003Cp\u003EModerne Fahrzeuge sehen nicht nur gut aus, sondern haben auch einen Nutzen für Dich. Nicht nur machen sie die Fahrt sicherer und angenehmer, sondern auch einfacher und das hilft natürlich auch bei der Prüfung. Mach Dir also keine Sorgen, dass Du im Sommer schwitzen oder im Winter frieren musst, oder wegen fehlender Berganfahrhilfe durchfällst.\u003C/p\u003E\u003Cp\u003E\u003Cbr\u003E\u003C/p\u003E\u003Cp\u003EModerne Ausbildungstechniken gewährleisten ein effizientes Lernen. Deshalb arbeiten wir mit Fernsehern, Animationen und interaktiven Darstellungen im Theorieunterricht und Du bekommst von uns einen Zugang zu einer mobilen Lern-App, sodass du wo Du willst und wann Du willst lernen kannst.\u003C/p\u003E\u003Cp\u003E\u003Cbr\u003E\u003C/p\u003E\u003Cp\u003EMit unserem raffinierten Schüler-Lehrer-Ökosystem bist auch Du vollständig integriert. Wir bieten Dir Deine eigenen Kosten- und Terminübersicht, damit Du immer im Big Picture bist.\u003C/p\u003E",
    id: "7b6ffefe-6f49-43c7-8482-d9d393c7c666",
    title: "Modern",
  },
  {
    "@id": "/api/bullet_informations/f19950da-f745-41c9-b203-e8a546106d64",
    "@type": "BulletInformation",
    content:
      "\u003Cp\u003EMach dir keine Sorgen, dass Du alleine auf Ämter gehen, oder komplizierten Papierkram verschicken musst. Wir sind dein einziger Bezugspunkt und alles läuft über uns. Du bekommst von uns alle Lehrmittel, wir begleiten Dich zur Prüfung - das heißt Du musst nicht alleine zum TÜV - und übernehmen den kompletten bürokratischen Teil.\u003C/p\u003E\u003Cp\u003E\u003Cbr\u003E\u003C/p\u003E\u003Cp\u003EAußerdem ist während der Theoriestunden für alkoholfreie Getränke gesorgt.\u003C/p\u003E",
    id: "f19950da-f745-41c9-b203-e8a546106d64",
    title: "Sorgenfrei",
  },
  {
    "@id": "/api/bullet_informations/fa314483-283f-4ceb-aa94-4c01028ca767",
    "@type": "BulletInformation",
    content:
      "\u003Cp\u003EExklusiv bei uns gibt es jeden Monat einen technischen Kurs in der Werkstatt.\u003C/p\u003E\u003Cp\u003E\u003Cbr\u003E\u003C/p\u003E\u003Cp\u003EWir beantworten euch eure Fragen und ermöglichen einen Einblick hinter die Kulissen von eurem Fahrschulfahrzeug; wir präsentieren euch die wichtigsten Wartungs- und Pflegearbeiten und ihr lernt, wie es richtig gemacht wird.\u003C/p\u003E\u003Cp\u003E\u003Cbr\u003E\u003C/p\u003E\u003Cp\u003EDas ist vorallem nützlich, da der Prüfer oft vor der Prüfung technische Fragen stellt.\u003C/p\u003E",
    id: "fa314483-283f-4ceb-aa94-4c01028ca767",
    title: "Monatlicher Werkstattkurs",
  },
];

export class Main extends React.Component<{}, IState> {
  constructor(props: {}) {
    super(props);
    this.state = {
      bulletInformation: BULLET_INFORMATION,
      loading: true,
    };
    this.prepareData();
  }

  public componentDidMount() {
    this.setMobileViewport();
  }

  public render() {
    return (
      <main className="main d-flex flex-column justify-content-between">
        <FullScreenLoader loading={this.state.loading} />
        <BlackFriday />
        <Header />

        <div className="main__middle d-flex">
          {this.state.bulletInformation != null && (
            <InformationCarousel data={this.state.bulletInformation} />
          )}

          <Hero />
        </div>

        <div className="main__tiles d-flex justify-content-between w-100">
          <Tile href="#pricing">Preise</Tile>
          <Tile href="#change-schools" style={{ wordBreak: "break-all" }}>
            Fahrschulwechsel
          </Tile>
          <Tile href="#vehicle-categories">Führerschein-Klassen</Tile>
          <Tile href="#contact">Kontakt</Tile>
        </div>
      </main>
    );
  }

  private prepareData = async () => {
    setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 50);

    // try {
    //   bulletInformation = await RestClient.getBulletInformation();
    //   console.log(bulletInformation);
    //   fetchUserAction();
    // } catch (e) {
    //   console.error(e);
    // } finally {
    //   this.setState({
    //     bulletInformation,
    //     loading: false,
    //   });
    // }
  };

  /**
   * Given the user is on a mobile device,
   * this function will manually set the
   * viewport to the intitial device dimensions,
   * so units depending on the viewport aren't negatively
   * manipulated by, for example, an opening keyboard.
   */
  private setMobileViewport() {
    const vh = window.innerHeight;
    const vw = window.innerWidth;
    const viewport = document.querySelector("meta[name=viewport]");

    if (!viewport || !/Mobi|Android/i.test(navigator.userAgent)) {
      return;
    }

    viewport.setAttribute(
      "content",
      `width=${vw}, height=${vh}, initial-scale=1, shrink-to-fit=no`
    );
  }
}
