import * as React from 'react';

import { Toggle } from '../../../../../components/Toggle';
import { CategoryCircle } from './components/CategoryCircle';
import './styles.scss';

interface IVehicleCategory {
  /**
   * The title of the vehicle category.
   * For example: 'B', 'BE', etc.
   */
  name: string;
  /**
   * A discription that an every user
   * can easily understand.
   */
  userFriendlyDescription: string;
  /**
   * The official description.
   */
  advancedDescription: string;
  /**
   * An icon to be displayed next to the
   * description,
   */
  icon: React.FunctionComponent<React.SVGAttributes<SVGElement>>;
}

interface IProps {
  /**
   * All vehicle categories.
   * The object key must correspond to
   * the `IVehicleCategory`s name property
   */
  vehicleCategories: IVehicleCategory[];
}

interface IState {
  selectedCategory: string;
  advancedDescriptionActive: boolean;
}

export class CategoryTable extends React.Component<IProps, IState> {
  public state = {
    advancedDescriptionActive: false,
    selectedCategory: this.props.vehicleCategories[0].name,
  };

  public render() {
    const selectedCategory = this.props.vehicleCategories.find(
      category => category.name === this.state.selectedCategory,
    );

    const getText = () => {
      if (!selectedCategory) {
        return null;
      }

      return this.state.advancedDescriptionActive
        ? selectedCategory.advancedDescription
        : selectedCategory.userFriendlyDescription;
    };

    return (
      <div className="vehicle-categories__table">
        <div className="vehicle-categories__table__circles">
          {this.renderCircles()}
        </div>
        <div className="vehicle-categories__table__separator" />
        <div className="vehicle-categories__table__content" style={{
          opacity: this.state.advancedDescriptionActive ? '1' : '0.99'
        }}>
          {getText()}
        </div>
        <div className="vehicle-categories__table__icon">
          {selectedCategory && <selectedCategory.icon />}
        </div>
        <Toggle
          containerStyle={{
            alignSelf: 'flex-end',
          }}
          onClick={this.onToggleClick}
          checked={this.state.advancedDescriptionActive}
          leftText="Simpel"
          rightText="Fachjargon"
        />
      </div>
    );
  }

  private renderCircles() {
    const circles = this.props.vehicleCategories.map(
      vehicleCategory => (
        <CategoryCircle
          onClick={this.setState.bind(this)}
          name={vehicleCategory.name}
          key={vehicleCategory.name}
          active={
            this.state.selectedCategory === vehicleCategory.name
          }
        />
      ),
    );

    return circles;
  }

  private onToggleClick = () => {
    this.setState({
      advancedDescriptionActive: !this.state
        .advancedDescriptionActive,
    });
  };
}
