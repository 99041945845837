import classNames from '@sindresorhus/class-names';
import * as React from 'react';

import './styles.scss';

interface IProps {
  checked: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  leftText: string;
  rightText: string;
  containerStyle?: React.CSSProperties;
}

export const Toggle: React.FC<IProps> = ({
  checked,
  onClick,
  leftText,
  rightText,
  containerStyle,
}) => (
  <div className="toggle" style={containerStyle}>
    <span className="toggle__label toggle__label--left">
      {leftText}
    </span>
    <input
      className="toggle__input"
      type="checkbox"
      checked={checked}
      readOnly={true}
    />
    <button
      className={classNames('toggle__button', {
        'toggle__button--active': checked,
      })}
      aria-label="Toggle"
      onClick={onClick}
    />
    <span className="toggle__label toggle__label--right">
      {rightText}
    </span>
  </div>
);

Toggle.defaultProps = {
  containerStyle: {},
};
