import { Section } from './../../../components/Section';
import * as React from 'react';

import { CategoryTable } from './components/CategoryTable';

import B_SVG from './B.svg';
import B96_SVG from './B96.svg';
import BE_SVG from './BE.svg';
import BF17_SVG from './BF17.svg';
import B_AUTOMATIK_SVG from './B_Automatik.svg';

import Preview from './preview.jpg';

const ID = 'vehicle-categories';

const VEHICLE_CATEGORIES = [
  {
    advancedDescription:
      'B | Kraftfahrzeuge - ausgenommen Kraftfahrzeuge der Klassen AM, A1, A2 und A mit einer zulässigen Gesamtmasse von nicht mehr als 3 500 kg, die zur Beförderung von nicht mehr als acht Personen außer dem Fahrzeugführer ausgelegt und gebaut sind (auch mit Anhänger mit einer zulässigen Gesamtmasse von nicht mehr als 750 kg oder mit Anhänger über 750 kg zulässiger Gesamtmasse, sofern die zulässige Gesamtmasse der Kombination 3 500 kg nicht übersteigt und im Inland dreirädrige Kraftfahrzeuge, im Falle eines Kraftfahrzeuges mit einer Motorleistung von mehr als 15 kW jedoch nur, soweit der Inhaber der Fahrerlaubnis mindestens 21 Jahre alt ist.',
    icon: B_SVG,
    name: 'B',
    userFriendlyDescription:
      'B | Autos bis 3.5 Tonnen. Man kann einen kleinen Anhänger an das Auto hängen - Die meist ausgestellte Führerscheinklasse.',
  },
  {
    advancedDescription:
      'BF17 | Kraftfahrzeuge - ausgenommen Kraftfahrzeuge der Klassen AM, A1, A2 und A mit einer zulässigen Gesamtmasse von nicht mehr als 3 500 kg, die zur Beförderung von nicht mehr als acht Personen außer dem Fahrzeugführer ausgelegt und gebaut sind (auch mit Anhänger mit einer zulässigen Gesamtmasse von nicht mehr als 750 kg oder mit Anhänger über 750 kg zulässiger Gesamtmasse, sofern die zulässige Gesamtmasse der Kombination 3 500 kg nicht übersteigt und im Inland dreirädrige Kraftfahrzeuge, im Falle eines Kraftfahrzeuges mit einer Motorleistung von mehr als 15 kW jedoch nur, soweit der Inhaber der Fahrerlaubnis mindestens 21 Jahre alt ist.\n\n Ab einem Alter von 16 ½ Jahren kann man die Zulassung zum BF 17 beantragen und die Ausbildung absolvieren. Gefahren werden darf nur mit Begleitperson und nur in Deutschland. Theoretische Prüfung frühestens drei Monate vor dem 17. Geburtstag, praktische Prüfung frühestens einen Monat vorher',
    icon: BF17_SVG,
    name: 'BF17',
    userFriendlyDescription:
      'BF17 | Das Gleiche wie Führerscheinklasse B, bloß, dass man mit Begleitperson schon ab einem Alter von 17 fahren darf.',
  },
  {
    advancedDescription:
      'BE | Fahrzeugkombinationen, die aus einem Zugfahrzeug der Klasse B und einem Anhänger oder Sattelanhänger bestehen, sofern die zulässige Gesamtmasse des Anhängers oder Sattelanhängers 3 500 kg nicht übersteigt.',
    icon: BE_SVG,
    name: 'BE',
    userFriendlyDescription:
      'BE | Beinhaltet B - Man kann zusätzlich Anhänger bis 3.5 Tonnen anhängen.',
  },
  {
    advancedDescription:
      'B96 | Fahrzeugkombinationen, die aus einem Zugfahrzeug der Klasse B und einem Anhänger bestehen, mit einer zulässigen Gesamtmasse des Anhängers von mehr als 750 kg und zulässiger Gesamtmasse der Fahrzeugkombination von mehr als 3.500 kg und nicht mehr als 4.250 kg.',
    icon: B96_SVG,
    name: 'B96',
    userFriendlyDescription:
      'B96 | Beinhaltet B - Man kann einen beliebig großen Anhänger anhängen, solange Fahrzeug und Anhänger ein Gewicht von 4.25 Tonnen nicht überschreiten. Der kleine Bruder von BE',
  },
  {
    advancedDescription:
      'B Automatik | Der "Automatikführerschein" ist keine eigene Führerscheinklasse - es handelt sich hierbei um eine vollwertige Fahrerlaubnis der Klasse B, die eine Beschränkung auf KFZ mit Automatikgetriebe erhält.\n\nEine Aufhebung der Beschränkung ist jedoch auf Antrag jederzeit möglich. Hierzu musst du in einer praktischen Prüfung die Fähigkeit nachweisen, ein Kraftfahrzeug mit Schaltgetriebe sicher im Straßenverkehr zu führen. Eine Fahrausbildung wie beim Ersterwerb und eine theoretische Prüfung sind hierzu nicht mehr erforderlich.',
    icon: B_AUTOMATIK_SVG,
    name: 'BA',
    userFriendlyDescription:
      'B Automatik | Eine vollwärtige Erlaubnis der Klasse B, bloß, dass man nur Fahrzeuge mit Automatikgetriebe fahren darf.',
  },
];

export const VehicleCategories = () => (
  <Section
    id={ID}
    key={ID}
    image={Preview}
    title="Führerscheinklassen"
  >
    <CategoryTable vehicleCategories={VEHICLE_CATEGORIES} />
  </Section>
);
