import classNames from '@sindresorhus/class-names';
import * as React from 'react';

import './styles.scss';

const mainClassName = 'vehicle-categories__table__circle';

interface IProps {
  name: string;
  onClick: (_: { selectedCategory: string }) => void;
  /**
   * Whether the current CategoryCircle
   * is selected.
   */
  active: boolean;
}

export const CategoryCircle: React.SFC<IProps> = ({
  name,
  onClick,
  active,
}) => {
  const props = {
    className: classNames(mainClassName, {
      [`${mainClassName}--active`]: active,
    }),
    onClick: () => onClick({ selectedCategory: name }),
  };
  return <div {...props}>{name}</div>;
};
