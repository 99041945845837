import classNames from '@sindresorhus/class-names';
import * as React from 'react';

import './styles.scss';

export interface ITableDataSingleCategory {
  /** The (initial) amount of units */
  amount: number;
  /** Whether the amount of units is editable */
  editable: boolean;
  /** Price of a single unit */
  price: number;
  /** Name of unit to display on front-end */
  name: string;
}

export interface ITableDataSingleExternalCosts {
  /** Name of an external cost */
  name: string;
  /** Price of an external cost */
  price: number;
}

export interface ITableDataSingle {
  /** Name of class to display on front-end */
  name: string;
  /** Categories */
  categories: ITableDataSingleCategory[];
  /** A description to display above table on front-end */
  description?: string;
  /** External costs */
  externalCosts?: ITableDataSingleExternalCosts[];
}

export type TableData = ITableDataSingle[];

interface IState {
  tableData: TableData;
  /** The name of the selected class */
  selectedClass: string;
}

interface IProps {
  tableData: TableData;
}

export class InteractivePriceTable extends React.Component<
  IProps,
  IState
> {
  public state = {
    selectedClass: this.props.tableData[0].name,
    tableData: this.props.tableData,
  };

  public render() {
    return (
      <div className="interactive-price-table">
        <div className="interactive-price-table__class-picker-wrapper">
          {this.renderClassNames()}
        </div>
        <span>{this.selectedClass.description}</span>
        {this.renderTable()}
        {this.renderExternalCosts()}
        {this.renderSum()}
        <small>
          *Dies ist lediglich eine Beispielrechnung. Externe Kosten
          sind nicht in dieser Rechnung enthalten.
        </small>
      </div>
    );
  }

  private onClassNameClick = (name: string) => () => {
    this.setState({
      selectedClass: name,
    });
  };

  private onCategoryChange = (
    className: string,
    categoryName: string,
  ) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const getNewAmount = (
      originalValue: number,
      eventValue: string,
    ) => {
      let newValue = parseInt(eventValue.replace(/^0+/g, ''), 10);
      newValue = Number.isNaN(newValue) ? 0 : newValue;
      return newValue > 999 ? originalValue : newValue;
    };

    // I'm sorry to whomever has to maintain this
    this.setState({
      tableData: this.state.tableData.map(
        tableDataSingle =>
          tableDataSingle.name !== className
            ? tableDataSingle
            : {
                ...tableDataSingle,
                categories: tableDataSingle.categories.map(
                  category =>
                    category.name !== categoryName
                      ? category
                      : {
                          ...category,
                          amount: getNewAmount(
                            category.amount,
                            event.currentTarget.value,
                          ),
                        },
                ),
              },
      ),
    });
  };

  private get classNames() {
    return this.state.tableData.map(td => td.name);
  }

  private get selectedClass() {
    return (
      this.state.tableData.find(
        td => td.name === this.state.selectedClass,
      ) || this.state.tableData[0]
    );
  }

  private renderClassNames() {
    return this.classNames.map(className => (
      <div
        key={className}
        onClick={this.onClassNameClick(className)}
        className={classNames(
          'interactive-price-table__class-picker',
          {
            'interactive-price-table__class-picker--selected':
              this.state.selectedClass === className,
          },
        )}
      >
        {className}
      </div>
    ));
  }

  private formatNumber(num: number) {
    return num
      .toFixed(2)
      .toString()
      .replace(/\.00$/, '')
      .replace('.', ',');
  }

  private renderTable() {
    const { selectedClass } = this;

    const renderTableRow = ({
      price,
      editable,
      amount,
      name,
    }: ITableDataSingleCategory) => {
      if (!editable && price === 0 && amount === 0) {
        return (
          <tr key={selectedClass.name + name}>
          <td>{name}</td>
          <td />
          <td />
          <td>Inklusive</td>
        </tr>
        )
      }

      return (
        <tr key={selectedClass.name + name}>
          <td>{name}</td>
          <td>
            {editable ? (
              <input
                type="number"
                step="1"
                max="100"
                value={this.formatNumber(amount)}
                onChange={this.onCategoryChange(
                  selectedClass.name,
                  name,
                )}
              />
            ) : (
              <span>{this.formatNumber(amount)}</span>
            )}
          </td>
          <td>{this.formatNumber(price)}€</td>
          <td>{this.formatNumber(amount * price)}€</td>
        </tr>
      );
    };

    return (
      <table className="interactive-price-table__table">
        <thead>
          <tr>
            <th />
            <th>Anzahl</th>
            <th>Preis</th>
            <th>Summe</th>
          </tr>
        </thead>
        <tbody>{selectedClass.categories.map(renderTableRow)}</tbody>
      </table>
    );
  }

  private renderExternalCosts() {
    const { selectedClass } = this;

    if (selectedClass.externalCosts == null) {
      return null;
    }

    const renderTableRow = ({
      price,
      name,
    }: ITableDataSingleExternalCosts) => (
      <tr key={selectedClass.name + name}>
        <td>{name}</td>
        <td>{this.formatNumber(price)}€</td>
      </tr>
    );

    return (
      <>
        <h3 className="mt-15 mb-5 text-align-center">
          Externe Kosten
        </h3>
        <table className="interactive-price-table__table interactive-price-table__table--small">
          <tbody>
            {selectedClass.externalCosts.map(renderTableRow)}
          </tbody>
        </table>
      </>
    );
  }

  private renderSum() {
    const { selectedClass } = this;

    const sum = selectedClass.categories.reduce((prev, curr) => {
      return prev + curr.amount * curr.price;
    }, 0);

    return (
      <div className="interactive-price-table__sum">
        {this.formatNumber(sum)}€*
      </div>
    );
  }
}
