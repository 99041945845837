import classNames from '@sindresorhus/class-names';
import * as React from 'react';

import Checkmark from './checkmark.svg';
import './styles.scss';

interface IProps {
  active: boolean;
  label: string;
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
}

export const ArticlePicker = (props: IProps) => (
  <div
    className={classNames(
      'article-picker',
      'd-flex',
      'align-items-center',
      'p-10',
      {
        'article-picker--active': props.active,
      },
    )}
    onClick={props.onClick}
  >
    <Checkmark className="article-picker__checkmark" />
    <span className="article-picker__label ml-15">{props.label}</span>
  </div>
);
