import { Section } from '../../../components/Section';
import * as React from 'react';

import Email from '../../../assets/email.svg';
import Instagram from '../../../assets/instagram.svg';
import Phone from '../../../assets/phone.svg';
import WhatsApp from '../../../assets/whatsapp.svg';
import { ContactIcon } from './components/ContactIcon';

import PreviewImage from './preview.jpg';

import './styles.scss';

const ID = 'contact';

export const Contact = () => (
  <Section id={ID} key={ID} image={PreviewImage} title="Kontakt">
    <div>
      <p>
        Du interessiert Dich für unser Angebot oder hast Fragen rund
        um Deinen Führerschein?
      </p>
      <p>
        Schick' einfach eine E-Mail / WhatsApp-Nachricht, ruf an, oder
        komm vorbei.
      </p>
      <p>Wir freuen uns auf Dich!</p>
    </div>

    <div className="icons">
      <ContactIcon
        href="mailto:info@fahrschule-grossumstadt.de"
        icon={Email}
        label="info@fahrschule-grossumstadt.de"
      />

      <ContactIcon
        href="https://api.whatsapp.com/send?phone=491715459816"
        icon={WhatsApp}
        label="WhatsApp"
      />

      <ContactIcon
        href="tel:+4960789304086"
        icon={Phone}
        label="06078 9304086"
      />

      <ContactIcon
        href="https://www.instagram.com/fahrschule.grossumstadt/"
        icon={Instagram}
        label="Instagram"
      />
    </div>

    <div className="opening-hours">
      <h3>Öffnungszeiten</h3>
      <table>
        <tbody>
          <tr>
            <h4>Büro</h4>
          </tr>
          <tr>
            <td>Montag - Freitag</td>
            <td className="pl-10 font-weight-bold">16:30 - 18:30</td>
          </tr>
          <tr>
            <h4 className="pt-20">Theorie</h4>
          </tr>
          <tr>
            <td>Dienstag</td>
            <td className="pl-10 font-weight-bold">18:30 - 20:00</td>
          </tr>
          <tr>
            <td>Donnerstag</td>
            <td className="pl-10 font-weight-bold">18:30 - 20:00</td>
          </tr>
          <tr>
            <td>Und nach Bedarf</td>
          </tr>
        </tbody>
      </table>
    </div>

    <h3>Anfahrt</h3>
    <p className="text-align-center font-size-very-large">In der ehemaligen Zulassungsstelle - Ecke Pfälzer Gasse - Obere Marktstraße 11</p>
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5143.147100144275!2d8.921434077703267!3d49.869254833012825!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47bd43972b373c6d%3A0x9c74b649c21d0b8a!2sFahrschule+Gro%C3%9F-Umstadt!5e0!3m2!1sen!2sde!4v1562010573256!5m2!1sen!2sde"
      width="100%"
      height="500px"
      frameBorder="0"
      style={{ border: '0' }}
      allowFullScreen={true}
    />
  </Section>
);
