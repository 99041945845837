import * as React from 'react';

import { Button } from '../Button';

import './styles.scss';

export const BlackFriday = () =>
  new Date() < new Date('2020-11-28T20:00:00') ? (
    <React.Fragment>
      <a
        className="black-friday-button"
        href="https://posts.gle/S6cdD"
        target="_blank"
        rel="noopener"
      >
        <Button label="BLACK FRIDAY ANGEBOT" />
      </a>
    </React.Fragment>
  ) : null;
