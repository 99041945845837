import * as React from 'react';

import './styles.scss';

interface IProps {
  icon: React.FunctionComponent<React.SVGAttributes<SVGElement>>;
  href: string;
  label: string;
}

export const ContactIcon = (props: IProps) => (
  <a className="contact-icon" href={props.href}>
    <div className="contact-icon__wrapper">
      <props.icon />
    </div>
    <div className="contact-icon__label">{props.label}</div>
  </a>
);
