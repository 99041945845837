import React, { useEffect, useState } from "react";

import { Section } from "../../../components/Section";

import {
  InteractivePriceTable,
  TableData,
} from "./components/InteractivePriceTable";
import PreviewImage from "./preview.jpeg";

export const Pricing: React.FC = () => {
  const [prices, setPrices] = useState<TableData>();

  useEffect(() => {
    (async () => {
      setPrices(
        await Promise.all(
          ["B", "B96", "BE", "BF17", "BAutomatik"].map((priceName) =>
            fetch(`/prices/${priceName}.json`).then((j) => j.json())
          )
        )
      );
    })();
  }, []);

  if (!prices) {
    return null;
  }

  return (
    <Section id="pricing" title="Preise" image={PreviewImage}>
      <>
        <p>
          Mit unserem Kostenrechner erhältst du eine grobe Übersicht, mit was
          für einem Preis Du beim Führerschein rechnen kannst. Die Anzahl der
          Fahrstunden ist von Schüler zu Schüler unterschiedlich; wie viele Du
          benötigst, musst Du selbst einschätzen.
        </p>

        <InteractivePriceTable tableData={prices} />
      </>
    </Section>
  );
};
