import { Elastic, Power1, Power2, TimelineMax, TweenMax } from 'gsap';
import * as React from 'react';

import CarSVG from '-!svg-react-loader!./car.svg';
import './styles.scss';

interface IProps {
  loading: boolean;
}

interface IState {
  shouldRender: boolean;
  animateOut: boolean;
}

// Easing functions
const p1 = () => 1.70158;
const p2 = () => p1() * 1.525;
const easeInOut = (p: number) =>
  (p *= 2) < 1 // tslint:disable-line
    ? 0.5 * p * p * ((p2() + 1) * p - p2())
    : 0.5 * ((p -= 2) * p * ((p2() + 1) * p + p2()) + 2);

export class FullScreenLoader extends React.Component<
  IProps,
  IState
> {
  public state: IState = {
    animateOut: false,
    shouldRender: true,
  };
  private timeline: TimelineMax;

  public componentDidMount() {
    TweenMax.set('#loading-car', {
      visibility: 'visible',
    });

    TweenMax.set('#bonnetStart', {
      y: 75,
    });

    TweenMax.set('#bumper', {
      alpha: 0,
      drawSVG: '50% 50%',
    });

    TweenMax.set('#shadow', {
      alpha: 0.2,
    });

    TweenMax.set('#wholeCar', {
      transformOrigin: '50% 50%',
      y: -30,
    });

    TweenMax.set('#frame', {
      scaleX: 0,
      transformOrigin: '50% 50%',
    });

    TweenMax.set(['#headlightL', '#headlightR'], {
      scale: 0,
      svgOrigin: '410 320',
    });

    TweenMax.set(['#tyreL', '#tyreR'], {
      transformOrigin: '50% 50%',
      y: -30,
    });

    TweenMax.set('#mirrorL', {
      rotation: 90,
      scale: 0,
      transformOrigin: '100% 100%',
      x: -10,
      y: 20,
    });

    TweenMax.set('#mirrorR', {
      rotation: -90,
      scale: 0,
      transformOrigin: '0% 100%',
      x: -10,
      y: 20,
    });

    this.timeline = new TimelineMax({
      repeat: 0,
      repeatDelay: 0,
    });

    this.timeline
      .set('#bumper', {
        alpha: 1,
      })
      .to('#bumper', 1, {
        drawSVG: '0% 100%',
        ease: Power1.easeInOut,
      })
      .from(
        '#shadow',
        1,
        {
          attr: {
            rx: 0,
            ry: '-=6',
          },
          ease: Power1.easeInOut,
        },
        '-=1',
      )
      .to(
        '#bonnetStart',
        0.9,
        {
          ease: Power2.easeInOut,
          y: 0,
        },
        '-=0.9',
      )
      .to(
        '#frame',
        1,
        {
          ease: Power2.easeInOut,
          scaleX: 1,
        },
        '-=1',
      )

      .to(
        ['#headlightL', '#headlightR'],
        1,
        {
          ease: Power2.easeInOut,
          scale: 1,
        },
        '-=1',
      )
      .to(
        '#wholeCar',
        1.2,
        {
          ease: easeInOut,
          y: 0,
        },
        '-=1',
      )

      .to(
        ['#tyreL', '#tyreR'],
        0.5,
        {
          ease: Power2.easeInOut,
          y: 0,
        },
        '-=1',
      )
      .to(
        ['#tyreL', '#tyreR'],
        0.2,
        {
          ease: Power2.easeOut,
          scaleX: 1.1,
        },
        '-=0.5',
      )
      .to(
        '#chassis',
        0.2,
        {
          ease: Power1.easeIn,
          transformOrigin: '50% 50%',
          y: 5,
        },
        '-=0.5',
      )
      .to(
        '#chassis',
        0.4,
        {
          y: 0,
        },
        '-=0.2',
      )

      .to(
        ['#tyreL', '#tyreR'],
        2,
        {
          ease: Elastic.easeOut.config(1, 0.8),
          scaleX: 1,
        },
        '-=0.4',
      )
      .to(
        ['#mirrorL', '#mirrorR'],
        1,
        {
          scale: 1,
          x: 0,
          y: 0,
        },
        '-=2.5',
      )
      .to(
        ['#mirrorL', '#mirrorR'],
        4,
        {
          ease: Elastic.easeOut.config(1, 0.2),
          rotation: 0,
        },
        '-=1.8',
      )
      .set('#shadow', {
        alpha: 0,
      })
      .set('#shadow', {
        alpha: 0,
      })
      .set('#shadowFollow', {
        alpha: 0.2,
      });

    this.timeline.timeScale(2);
  }

  public componentWillReceiveProps(props: IProps) {
    if (this.props.loading && !props.loading) {
      /**
       * When the platform is no longer loading,
       * make the car go, as soon as the initial animation is over
       */
      setTimeout(() => {
        this.setState({
          animateOut: true,
        });
        this.timeline.to('#wholeCar', 2, {
          ease: Power1.easeIn,
          scale: 1.82,
          y: 600,
        });

        // As soon as the car is out of the screen, stop rendering the FullScreenLoader
        setTimeout(() => {
          this.setState({
            shouldRender: false,
          });
        }, (this.timeline.totalDuration() - this.timeline.totalTime()) * 1000);
      }, (this.timeline.duration() / 2 - this.timeline.time()) * 1000);
    }
  }

  public render() {
    return this.state.shouldRender ? (
      <div
        id="full-screen-loader"
        className={
          this.state.animateOut
            ? 'full-screen-loader--animate-out'
            : ''
        }>
        <CarSVG />
      </div>
    ) : null;
  }
}
