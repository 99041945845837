import React from 'react';

import '../styles/main.scss';
import { Main } from '../scenes/Main';
import { Pricing } from '../scenes/sections/Pricing';
import { ChangeSchools } from '../scenes/sections/ChangeSchools';
import { VehicleCategories } from '../scenes/sections/VehicleCategories';
import { Contact } from '../scenes/sections/Contact';
import { Footer } from '../scenes/Footer';
import { MetaData } from '../components/MetaData';

export default () => (
  <div id="root">
    <Main />

    <Pricing />
    <ChangeSchools />
    <VehicleCategories />
    <Contact />
    <Footer />

    <MetaData />
  </div>
);
